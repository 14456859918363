import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../layouts/index";

import kevin from "../images/kevin-hard.jpg";
import khuram from "../images/khuram-joura.jpg";

// markup
export default function IndexPage() {
  return (
    <Layout>
      <Helmet
        title="VIP Dine - Add table reservations and click-and-collect ordering to your
        restaurant's website"
      />
      <section className="section hero vip-hero">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>
                Maximise your restaurant's potential with{" "}
                <span className="product-name yellow">VIP Dine</span>
              </h1>
              <p>
                Add table reservations and click-and-collect ordering to your
                website
              </p>
            </div>
          </div>
        </div>
        <div className="overlay"></div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row reverse stretch">
            <div className="col image reserved-image"></div>

            <div className="col">
              <h2>
                <span className="product-name">Reserved</span> - online table
                reservations on your website
              </h2>
              <p>
                At a really low cost, you reap benefits of increased covers
                while showcasing your restaurant's professional webpages with a
                reservations feature in it.
              </p>
              <p>Take your table reservation volume to the next level.</p>
              <Link to="/reserved/" className="btn btn-primary">
                Fnd out more
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className="container hr">
        <div className="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row stretch">
            <div className="col image ordered-image"></div>

            <div className="col">
              <h2>
                <span className="product-name">Ordered</span> -
                click-and-collect ordering on your website
              </h2>
              <p>
                Start taking online orders in a matter of minutes. Let your
                customers use the easiest way to order and pay giving you that
                potential to sell more at really affordable costs.
              </p>
              <p>Start selling online easily and securely today.</p>
              <Link to="/ordered/" className="btn btn-primary">
                Find out more
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className="container hr">
        <div className="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>
                About <span className="product-name">VIP Dine</span>
              </h2>
              <p>
                Established in 2019, VIP Dine Ltd is a UK based company formed
                with an idea of being an IT enabler. Our aim is to provide
                affordable services to the restaurant and hospitality industry
              </p>
              <p>
                With more than 20 years of experience in hospitality industry
                the founders of VIP Dine have a vision to provide end-to-end
                cost effective IT solutions for restaurant industry enabling the
                industry to reach the immense potential this huge industry has.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container hr">
        <div className="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="text-center">Reviewed by Industry experts</h2>
            </div>
          </div>
        </div>

        <div className="container testimonials">
          <div className="row stretch">
            <div className="col">
              <div className="testimonial-card">
                <blockquote className="text">
                  "Our business has grown substantially since we started using
                  Reserved. It is a powerful yet easy system to use for the
                  restaurant. At such an affordable pricing, it really is a
                  must-have."
                </blockquote>
                <div className="footer">
                  <img className="image" src={khuram} alt="Khuram Joura" />
                  <div className="person">Khuram Joura</div>
                  <div className="company">Atlantis Lounge, Slough</div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className=" testimonial-card">
                <blockquote className="text">
                  "We were quickly able to setup and run VIP Dine products. The
                  portal for configuring and managing reservations and
                  click-and-collect orders is amazingly simple and easy. I
                  surely recommend it"
                </blockquote>
                <div className="footer">
                  <img className="image" src={kevin} alt="Kevin Hard" />
                  <div className="person">Kevin Hard</div>
                  <div className="company">Doubletree by Hilton</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
